<template>
  <div class="main_dashboard__sidebar" :class="{'main_dashboard__sidebar--openSidebar': $parent.openSidebar}">
    <!-- Logo sidebar -->
    <div :class="[!$parent.openSidebar ? 'py-5 px-4 d-flex' : 'py-4 px-md-3 px-xl-4']" class="logo">
      <router-link :to="{ name: 'home' }">
        <img src="@/assets/img/api.monitor.text.white.png" class="img-fluid" v-if="!$parent.openSidebar">
        <img src="@/assets/img/logo.white.png" class="img-fluid" v-else>
      </router-link>
      <b-button @click="$parent.openSidebar = !$parent.openSidebar" class="bg-transparent border-0 p-0" v-if="!$parent.openSidebar">
        <img src="@/assets/img/toggle2.png" width="30">
      </b-button>
    </div>

    <!-- Options sidebar -->
    <ul>
      <!-- Reports -->
      <li class="link-collapse mb-0" :class="[!$parent.openSidebar ? '' : 'py-2']">
        <span v-b-toggle.reports class="mb-0 py-4">
          <img src="@/assets/img/reports.png" width="30" :class="[!$parent.openSidebar ? 'me-3' : 'mx-auto']">
          <label v-show="!$parent.openSidebar">Reports</label>
          <vue-fontawesome icon="chevron-up" class="when-open ms-auto" v-show="!$parent.openSidebar"></vue-fontawesome>
          <vue-fontawesome icon="chevron-down" class="when-closed ms-auto" v-show="!$parent.openSidebar"></vue-fontawesome>
        </span>
        <b-collapse accordion="my-accordion" id="reports" role="tabpanel" v-show="!$parent.openSidebar">
          <ul>
            <li>
              <router-link :to="{name: ''}">Item #1</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>

      <!-- Analyze results -->
      <li class="link-collapse mb-0" :class="[!$parent.openSidebar ? '' : 'py-2']">
        <span v-b-toggle.analyze-results class="mb-0 py-4">
          <img src="@/assets/img/analyze.results.png" width="30" :class="[!$parent.openSidebar ? 'me-3' : 'mx-auto']">
          <label v-show="!$parent.openSidebar">Analyze results</label>
          <vue-fontawesome icon="chevron-up" class="when-open ms-auto" v-show="!$parent.openSidebar"></vue-fontawesome>
          <vue-fontawesome icon="chevron-down" class="when-closed ms-auto" v-show="!$parent.openSidebar"></vue-fontawesome>
        </span>
        <b-collapse accordion="my-accordion" id="analyze-results" role="tabpanel" v-show="!$parent.openSidebar">
          <ul>
            <li>
              <router-link :to="{name: ''}">Item #1</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>

      <!-- Program monitor -->
      <li class="link-collapse mb-0" :class="[!$parent.openSidebar ? '' : 'py-2']">
        <span v-b-toggle.program-monitor class="mb-0 py-4">
          <img src="@/assets/img/program.monitor.png" width="30" :class="[!$parent.openSidebar ? 'me-3' : 'mx-auto']">
          <label v-show="!$parent.openSidebar">Program monitor</label>
          <vue-fontawesome icon="chevron-up" class="when-open ms-auto" v-show="!$parent.openSidebar"></vue-fontawesome>
          <vue-fontawesome icon="chevron-down" class="when-closed ms-auto" v-show="!$parent.openSidebar"></vue-fontawesome>
        </span>
        <b-collapse accordion="my-accordion" id="program-monitor" role="tabpanel" v-show="!$parent.openSidebar">
          <ul>
            <li>
              <router-link :to="{name: ''}">Item #1</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>

      <!-- Global variables -->
      <li class="link-collapse mb-0" :class="[!$parent.openSidebar ? '' : 'py-2']">
        <router-link :to="{ name: '' }" :class="[!$parent.openSidebar ? 'link-desk py-4' : 'link-mobile mx-auto p-0']">
          <img src="@/assets/img/global.variables.png" width="30" :class="[!$parent.openSidebar ? 'me-3' : '']">
          <label v-if="!$parent.openSidebar">Global variables</label>
        </router-link>
      </li>

      <!-- Groups -->
      <li class="link-collapse mb-0" :class="[!$parent.openSidebar ? '' : 'py-2']">
        <span v-b-toggle.group class="mb-0 py-4">
          <img src="@/assets/img/groups.png" width="30" :class="[!$parent.openSidebar ? 'me-3' : 'mx-auto']">
          <label v-show="!$parent.openSidebar">Groups</label>
          <vue-fontawesome icon="chevron-up" class="when-open ms-auto" v-show="!$parent.openSidebar"></vue-fontawesome>
          <vue-fontawesome icon="chevron-down" class="when-closed ms-auto" v-show="!$parent.openSidebar"></vue-fontawesome>
        </span>
        <b-collapse accordion="my-accordion" id="group" role="tabpanel" v-show="!$parent.openSidebar">
          <ul>
            <li>
              <router-link :to="{name: ''}">Item #1</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>

      <!-- Account -->
      <li class="link-collapse mb-0" :class="[!$parent.openSidebar ? '' : 'py-2']">
        <span v-b-toggle.account class="mb-0 py-4">
          <img src="@/assets/img/account.png" width="25" :class="[!$parent.openSidebar ? 'me-3' : 'mx-auto']">
          <label v-show="!$parent.openSidebar">Account</label>
          <vue-fontawesome icon="chevron-up" class="when-open ms-auto" v-show="!$parent.openSidebar"></vue-fontawesome>
          <vue-fontawesome icon="chevron-down" class="when-closed ms-auto" v-show="!$parent.openSidebar"></vue-fontawesome>
        </span>
        <b-collapse accordion="my-accordion" id="account" role="tabpanel" v-show="!$parent.openSidebar">
          <ul>
            <li>
              <router-link :to="{name: ''}">View profile</router-link>
            </li>
            <li>
              <router-link :to="{name: ''}">Delegate access</router-link>
            </li>
            <li>
              <router-link :to="{name: ''}">Notifications</router-link>
            </li>
            <li>
              <router-link :to="{name: ''}">Settings</router-link>
            </li>
            <li>
              <router-link :to="{name: ''}">Reset password</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'logout' }">Logout</router-link>
            </li>
          </ul>
        </b-collapse>
      </li>

      <!-- Notifications -->
      <li class="link-collapse mb-0 d-lg-none">
        <router-link :to="{ name: '' }" :class="[!$parent.openSidebar ? 'link-desk py-4' : 'link-mobile mx-auto my-4']">
          <b-avatar :src="require('@/assets/img/bell.png')" size="30" badge badge-top :class="[!$parent.openSidebar ? 'me-3' : '']"></b-avatar>
          <label v-if="!$parent.openSidebar">Notifications</label>
        </router-link>
      </li>
      <!-- Messages -->
      <li class="link-collapse mb-0 d-lg-none">
        <router-link :to="{ name: '' }" :class="[!$parent.openSidebar ? 'link-desk py-4' : 'link-mobile mx-auto my-4']">
          <b-avatar :src="require('@/assets/img/messages.png')" size="30" :class="[!$parent.openSidebar ? 'me-3' : '']"></b-avatar>
          <label v-if="!$parent.openSidebar">Messages</label>
        </router-link>
      </li>
    </ul>

    <!-- Footer sidebar -->
    <ul v-if="$parent.openSidebar" class="py-4">
      <li class="link-collapse">
        <router-link :to="{ name: '' }" :class="[!$parent.openSidebar ? 'link-desk' : 'link-mobile mx-auto']">
          <img src="@/assets/img/facebook.png" width="30"/>
        </router-link>
      </li>
      <li class="link-collapse">
        <router-link :to="{ name: '' }" :class="[!$parent.openSidebar ? 'link-desk' : 'link-mobile mx-auto']">
          <img src="@/assets/img/instagram.png" width="30" />
        </router-link>
      </li>
    </ul>
    <b-container fluid class="sidebar-foot" v-else>
      <b-row>
        <b-col cols="12" class="text-center social mb-3" align-self="center">
          <img src="@/assets/img/facebook.png" width="30" class="me-3" />
          <img src="@/assets/img/instagram.png" width="30" />
        </b-col>
        <b-col cols="12" class="p-0 text-center text-xl-start">
          <p class="mb-0">
            &copy; 2022 Apimonitor24 |
            <a href="#" class="text-decoration-none">apimonitor24.com</a> | Do Genius On, LLC.
            All rights reserved
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: 'Sidebar_dashboard',
  data() {
    return {
      openSidebar: false,
      // optionSelected: false,
    };
  },
  methods: {
    optionSelected(e){
      console.log(e.path[2].classList.add('active'));
    }
  }
};
</script>
