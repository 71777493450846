<template>
  <div>
    <div class="main_dashboard">
      <Sidebar/>
      <div class="main_dashboard__main_content" :class="{'main_dashboard__main_content--openSidebar': openSidebar}">
        <Navbar/>
        <router-view/>
      </div>
    </div>
    <div class="main_dashboard__sidebar--backdrop" v-if="!openSidebar" @click="openSidebar = true"></div>
  </div>
</template>

<script>
import Navbar from '@/components/Dashboard/Navbar.vue'
import Sidebar from '@/components/Dashboard/Sidebar.vue';

export default {
  name: 'Dashboard',
  components: {
    Sidebar,
    Navbar,
  },
  data() {
    return {
      openSidebar: false
    };
  },
  beforeCreate() {
    if (!this.$session.exists()) {
      this.$router.push({ name: 'login' });
    }
  },
};
</script>
